export const marketplaceAddress = "0x660C56a3f42D9527324af8A51CBe78E8f2Db17aE";
export const auctionAddress = "0xFA2238eEb4E2E5612853BF29f925a36f971F9010";

export const manifoldOpenEditionAddress =
  "0x26BBEA7803DcAc346D5F5f135b57Cf2c752A02bE";

export const contractAddress = "0xCF034d16C5D6cA9401CC755236e437fd92dB1C6d";

export const artistId = 572;
export const artistAddress = "0xf62C22A4Fb55c6ef0e2d298D56Fb9fd781de0F2C";

export const projectName = "Escuela de Fantasía";
export const projectLink = "https://franrodriguez.ninfa.io";

export const etherscanUrl = "https://etherscan.io";
export const basescanUrl = "https://basescan.org";

export const startingDate = 1726502400000;

export const artistName = "Fran Rodríguez";
export const bio = [
  'Fran Rodríguez aka lacabezaenlasnubes (spanish for "head in the clouds")  is a digital artist based in Spain.',
  "He is famously known for his  striking images depicting his interpretation of humanity's place in the  universe. Fran's work is deeply inspired by his early fondness of  surrealism and psychedelia.",
  "In his 20 year career, he has worked with  bands such as Weezer, Tame Impala and Tycho creating visual elements for  their concerts and albums and his work is exhibited and sold all over  the world",
];

export const ninfaUrl = "https://ninfa.io";

export const artistUrl = "https://ninfa.io/user/@Fran%20Rodr%C3%ADguez";
export const artistTwitter = "https://x.com/Fran_nubes";
export const artistInsta = "https://www.instagram.com/lacabezaenlasnubes";

export const interviewPath =
  "/interview/A-conversation-with-Fran-Rodr%C3%ADguez-20";
export const articlePath =
  "/article/Escuela-de-Fantasía:-Fran-Rodríguez’s-Dialogue-with-Surrealism-Collage-and-the-intersection-of-past-and-future-21";
export const standard = "ERC-721";
export const mintedOn = "Manifold";
export const totalPieces = "5";

export const validChainId = 1;

export const openEdition = {
  title: "Placeholder",
  description: "",
  price: 0,
  instanceId: 0,
  contractAddress: "0x0B4B102742A68B422B02563CFe226a3ba2bA3420",
  tokenId: 1,
  originalImageUrl: "",
  metadata: "",
  startDate: 1715097600,
  endDate: 1715184000,
} as const;

export const manifoldFee = 0.0005;

export const compressedImageBaseUrl = "https://images.ninfa.io/nfts/original";
